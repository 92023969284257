<template>
  <div>
    <ModalMailDetail
      v-if="selectedMailLog && showMailDetail"
      :showModal="selectedMailLog && showMailDetail"
      :onClickCancel="hideModal"
      :mailLog="selectedMailLog"
    />

    <table class="table is-fullwidth is-striped is-hoverable">
      <thead>
        <tr>
          <th>{{
                $t('Components.Reservation.MailLog.Table_HeadSentOn')
              }}</th>
          <th>{{
                $t('Components.Reservation.MailLog.Table_HeadFrom')
              }}</th>
          <th>{{
                $t('Components.Reservation.MailLog.Table_HeadTo')
              }}</th>
          <th>{{
                $t('Components.Reservation.MailLog.Table_HeadSubject')
              }}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(mailLog, index) in mailLogs"
          :key="index"
        >
          <td>
            <div class="no-text-wrap">{{ mailLog.CreatedOn | longDateFormat($i18n.locale) }}</div>
            <div class="is-size-7">{{ mailLog.CreatedByName }}</div>
          </td>
          <td>{{ mailLog.From }}</td>
          <td>{{ mailLog.To }}</td>
          <td>{{ mailLog.Subject }}</td>
          <td>
            <a
              @click="setShowMailDetail(mailLog)"
              class=""
            >
              <span class="icon">
                <font-awesome-icon :icon="['fas', 'info-circle']" />
              </span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

const ModalMailDetail = () => import('@/components/Mails/ModalMailDetail')

export default {
  components: {
    ModalMailDetail,
  },

  props: {
    mailLogs: {
      type: Array,
      default: function() {
        return []
      },
    },
  },

  data() {
    return {
      selectedMailLog: null,
      showMailDetail: false,
    }
  },

  computed: {
  },

  created() { },

  methods: {
    setShowMailDetail(mailLog) {
      this.selectedMailLog = mailLog
      this.showMailDetail = true
    },

    hideModal() {
      this.selectedMailLog = null
      this.showMailDetail = true
    },
  },
}
</script>

<style></style>
